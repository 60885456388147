import React, { Component } from "react"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"

import BackButton from "../components/BackButton"
import SiteContext from "../context/SiteContext"
import FixedMenu from "../components/FixedMenu"
import SEO from "../components/seo"

class ThankYou extends Component {
  static contextType = SiteContext

  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    let site = "finestone"
    this.context.changeSite(site)
  }

  handleSubmit = async e => {
    e.preventDefault()
    console.log("submit")

    const portalId = "1295364"
    const formGuid = "cc80a86d-987d-4cf1-be54-8d4baf83e31e"
    console.log(e.target.firstname.value)
    console.log(e.target.lastname.value)
    console.log(e.target.email.value)
    console.log(e.target.comments.value)

    const data = {
      fields: [
        {
          objectTypeId: "0-1",
          name: "email",
          value: e.target.email.value,
        },
        {
          objectTypeId: "0-1",
          name: "firstname",
          value: e.target.firstname.value,
        },
        {
          objectTypeId: "0-1",
          name: "lastname",
          value: e.target.lastname.value,
        },
        {
          objectTypeId: "0-1",
          name: "comment_or_question",
          value: e.target.comments.value,
        },
      ],
      legalConsentOptions: {
        consent: {
          consentToProcess: true,
          text:
            "I agree to allow MBCC-Group to store and process my personal data.",
          communications: [
            {
              value: this.state.checked,
              subscriptionTypeId: 999,
              text: "I agree to receive other communications from MBCC-Group.",
            },
          ],
        },
      },
    }

    console.log(data)

    const apiResponse = await fetch(
      `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      }
    )

    if (apiResponse.status === 200) {
      this.setState({ thankyou: true })
    }
  }

  render() {
    return (
      <SiteContext.Consumer>
        {siteContext => (
          <>
            <SEO title="Contact Us" />
            <div className="wrapper app-header-style-text">
              <div className="content-wrapper">
                <div className="app-header">
                  <div className="app-header-content">
                    <h1 className="app-header__title">Thank you</h1>
                    <div className="spacer"></div>
                  </div>
                </div>
                <div className="contact-form__wrapper">
                  <p className={`site--finestone thankyou`}>
                    <div className="spacer"></div>
                    <div className="spacer"></div>
                    Thank you for your inquiry. We will respond as soon as
                    possible.
                  </p>
                </div>
              </div>
              <FixedMenu site="finestone" />
            </div>
          </>
        )}
      </SiteContext.Consumer>
    )
  }
}

export default ThankYou
